<template>
    <PageWrapper breadcrumbs>
        <NebulaGridRow type="flex">
            <NebulaColumn
                :columns="6"
                :offset="3"
                :mdSize="8"
                :mdOffset="0"
            >
                <PageHeader :title="`Send a Direct Request to ${connector.firstName}`" />

                <ParticipantCard class="direct-request__participant-card" :participant="participantData" />

                <p>
                    Please enter details below regarding how you would like {{ connector.firstName }} to engage in a virtual classroom visit.
                    {{ connector.firstName }} will get emailed the details and will accept or decline your request.
                </p>

                <p>
                    If you prefer to open the request to the Career Connect volunteer community,
                    <RouterLink to="/request/new" class="new-request__description-link">Create an Open Request</RouterLink>
                </p>
                <RequestForm :connector="connector" />
            </NebulaColumn>
        </NebulaGridRow>
    </PageWrapper>
</template>

<script>
import {
    NebulaGrid,
} from '@discoveryedu/nebula-components';

import PageHeader from '@/components/shared/PageHeader.vue';
import RequestForm from '@/components/shared/requests/RequestForm.vue';
import PageWrapper from '@/components/shared/layout/PageWrapper.vue';
import ParticipantCard from '@/components/shared/ParticipantCard.vue';

import createPayload from '@/mixins/data/createPayload';

export default {
    name: 'NewDirectRequest',
    components: {
        RequestForm,
        NebulaColumn: NebulaGrid.NebulaColumn,
        NebulaGridRow: NebulaGrid.NebulaGridRow,
        PageHeader,
        PageWrapper,
        ParticipantCard,
    },
    mixins: [createPayload],
    computed: {
        connector() {
            return this.$store.state.page.employee.data;
        },
        flags() {
            return this.$store.getters.flags;
        },
        participantData() {
            if (!this.connector) {
                return {};
            }

            const {
                employeeId: id,
                firstName,
                lastName,
                preferredName,
                avatarUrl,
                title,
                organizations,
                direct_requests_active: directRequestsActive,
            } = this.connector;

            return {
                id, firstName, lastName, preferredName, avatarUrl, title, organizations, directRequestsActive,
            };
        },
    },
    data() {
        return {
        };
    },
    methods: {
    },
};
</script>

<style lang="stylus">
.new-request {
    &__action-row, &__actions {
        @media (min-width: $nebula-breakpoints-tablet-portrait) {
            display: flex;
            justify-content: flex-end;
        }
    }
    &__action-row {
        margin-block-start: $nebula-space-2x;
    }

    &__actions {
        margin-block-start: $nebula-space-4x;
        gap: $nebula-space-2x;
    }

    &__datepicker-label {
        prepop-label();
    }

    &__description-link {
        link();
    }
}
</style>
