<template>
    <PageWrapper breadcrumbs>
        <NebulaGridRow type="flex">
            <NebulaColumn :columns="6" :offset="3" :mdSize="8" :mdOffset="0" :smOffset="0">
                <PageHeader :title="$t('title', { ns: 'directrequest' })" />
                <PageSubheader class="direct-request__subheader" :description="$t('description', { ns: 'directrequest' })" />

                <RouterLink
                    ref="newRequestLink"
                    to="/request/new-direct"
                    class="direct-request__description-link"
                    data-click-type="Direct Request: Educator"
                    data-click-name="Create a New Request"
                >
                    {{ $t('link-text', { ns: 'directrequest' }) }}
                </RouterLink>
                <NebulaGridRow type="flex" class="direct-request__participant-wrapper">
                    <GrayBoxLoader v-if="loading.connector" class="direct-request__participant-loader" rounded />
                    <ParticipantCard v-else class="direct-request__participant-card" :participant="participantData" />
                </NebulaGridRow>
                <div class="direct-request__select-wrapper">
                    <GrayBoxLoader class="direct-request__select-loader" rounded v-if="loading.requests" />
                    <ProfileFormField
                        v-else
                        class="direct-request__select"
                        name="request"
                        :inputGroupData="formData.request"
                        :validation="validateInput"
                        inputType="select"
                        @update-select="populateSelectedRequest"
                    >
                        <template v-slot:customOptions>
                            <NebulaSelectOption
                                v-for="(option, index) in requestOptions"
                                v-bind="option"
                                :key="index"
                            />
                        </template>
                    </ProfileFormField>
                </div>
                <div v-if="formData.request.value" class="direct-request__selected-details">
                    <div class="direct-request__selected-title-group">
                        <h3 class="direct-request__selected-type">{{ getTypeDisplay(selectedRequest.type) }}</h3>
                        <h4 class="direct-request__selected-name">{{ selectedRequest.title }}</h4>
                    </div>
                    <h5 class="direct-request__selected-subhead">Details</h5>
                    <DetailBlock :data="selectedRequest" />
                    <NebulaButton
                        size="l"
                        class="direct-request__details-button"
                        text="View Details"
                        type="ghost"
                        routerLink
                        :link="requestDetailsLink"
                    />
                </div>
                <NebulaGridRow type="flex">
                    <NebulaColumn :columns="12" class="direct-request__actions">
                        <NebulaButton v-if="!loadingSubmit" type="flat" text="Cancel" :link="connector && `/connector/${connector.employeeId}`" />
                        <NebulaButton
                            :isDisabled="submitDisabled"
                            :text="loadingSubmit ? 'Processing' : 'Submit'"
                            @click="handleSubmit"
                            data-click-type="Direct Request: Educator"
                            data-click-name="Submit"
                        />
                    </NebulaColumn>
                </NebulaGridRow>
            </NebulaColumn>
        </NebulaGridRow>
    </PageWrapper>
</template>

<script>
import {
    NebulaButton,
    NebulaGrid,
    NebulaSelectOption,
} from '@discoveryedu/nebula-components';
import PageWrapper from '@/components/shared/layout/PageWrapper.vue';
import PageHeader from '@/components/shared/PageHeader.vue';
import PageSubheader from '@/components/shared/layout/PageSubheader.vue';
import ParticipantCard from '@/components/shared/ParticipantCard.vue';
import ProfileFormField from '@/components/shared/Profile/ProfileFormField.vue';
import DetailBlock from '@/components/dashboard/DetailBlock.vue';
import GrayBoxLoader from '@/components/shared/Loader/GrayBox.vue';

import createPayload from '@/mixins/data/createPayload';
import validation from '@/mixins/form/validation';
import getOrdinalSuffix from '@/utils/getOrdinalSuffix';

import {
    timestampNow,
    epochsToTimeAndDate,
} from '@/data/placeholder/date';
import { requestableStatuses, awaitingConfirmation } from '@/constants';
import {
    typeOptions,
} from '@/data/formData';
import formatTime from '@/utils/formatTime';

export default {
    name: 'DirectRequest',
    components: {
        DetailBlock,
        GrayBoxLoader,
        PageHeader,
        PageWrapper,
        PageSubheader,
        ParticipantCard,
        ProfileFormField,
        NebulaButton,
        NebulaGridRow: NebulaGrid.NebulaGridRow,
        NebulaColumn: NebulaGrid.NebulaColumn,
        NebulaSelectOption,
    },
    mixins: [validation, createPayload],
    computed: {
        connector() {
            return this.$store.state.page.employee.data;
        },
        flags() {
            return this.$store.getters.flags;
        },
        participantData() {
            if (!this.connector) {
                return {};
            }

            const {
                employeeId: id,
                firstName,
                lastName,
                preferredName,
                avatarUrl,
                title,
                organizations,
                direct_requests_active: directRequestsActive,
            } = this.connector;

            return {
                id, firstName, lastName, preferredName, avatarUrl, title, organizations, directRequestsActive,
            };
        },
        loadingSubmit() {
            return this.$store.state.status.request_direct_submit.loading;
        },
        requestDetailsLink() {
            return `/request/${this.selectedRequest.requestId}`;
        },
        submitDisabled() {
            return this.loadingSubmit;
        },
    },
    data() {
        return {
            loading: {
                requests: true,
                connector: true,
            },
            connectorId: '',
            formData: {
                request: {
                    value: '',
                    display: 'Select a request',
                    required: true,
                    requiredError: 'Select a request to submit directly',
                    errorString: null,
                    error: null,
                    status: '',
                },
            },
            rawRequestOptions: [],
            requestOptions: [],
            selectedRequest: {},
        };
    },
    methods: {
        getTypeDisplay(type) {
            if (!type) {
                return '';
            }
            const typeObj = typeOptions.find((each) => each.value === type);
            return typeObj.text;
        },
        async handleSubmit() {
            // run validation (from mixin) first. This also populates the validation results
            await this.handleSaveAndContinue(true);

            const { request: requestId } = this.validationResults;

            if (!requestId) {
                return;
            }

            this.$store.dispatch('updateLoading', { key: 'request_direct_submit', status: true });
            const payload = await this.createPayload({
                employeeId: this.connectorId,
                requestId,
            });

            await this.$store.dispatch('submitDirectRequest', payload);

            const statusPayload = await this.createPayload({ status: awaitingConfirmation, requestId });
            await this.$store.dispatch('updateRequest', statusPayload);

            this.$store.dispatch('updateLoading', { key: 'request_direct_submit', status: false });
            this.$router.push(`/request/${requestId}`);
        },
        populateSelectedRequest() {
            const selectedId = this.formData.request.value;
            const match = this.rawRequestOptions.find((each) => each.requestId === selectedId);

            this.selectedRequest = match;
        },
    },
    async mounted() {
        // Move direct link to within description
        const { newRequestLink } = this.$refs;
        const linkText = newRequestLink.$el.textContent.toLowerCase().trim();
        const requestLinkEl = newRequestLink.$el;
        requestLinkEl.remove();

        const subheader = document.querySelector('.direct-request__subheader');

        const description = subheader.querySelector('p');
        const regex = new RegExp(`(?<intro>.+)(?<link>${linkText})(?<end>.+)`);
        const matches = regex.exec(subheader.textContent);

        const { intro, end } = matches.groups;

        description.innerText = '';
        description.append(intro, requestLinkEl, end);

        const { id: employeeId } = this.$route.params;
        this.connectorId = employeeId;
        if (!employeeId) {
            this.$router.push('/');
        }

        const payload = await this.createPayload({ employeeId });

        const connectorFetch = () => this.$store.dispatch('viewEmployee', payload);

        const rawConnectParams = [['limit', 20], ['startDate', timestampNow()]];
        requestableStatuses.forEach((status) => rawConnectParams.push(['status', status]));

        const connectParams = new URLSearchParams(rawConnectParams).toString();
        const connectPayload = await this.createPayload({ params: connectParams });

        const requestsFetch = () => this.$store.dispatch('getRequests', connectPayload);

        await Promise.all([
            connectorFetch(),
            requestsFetch(),
        ]);

        this.loading.connector = false;

        this.rawRequestOptions = this.$store.state.page.requests;

        this.rawRequestOptions.forEach((request) => {
            const selectOption = {};
            const {
                requestId,
                startDateTime,
                endDateTime,
                type,
            } = request;
            selectOption.value = requestId;

            // type is stored as an integer, grab the associated string
            const typeString = this.getTypeDisplay(type);

            const { dayOfWeek, month, date } = epochsToTimeAndDate(startDateTime, endDateTime);
            const dayOfMonth = date.getDate();
            const time = formatTime(startDateTime * 1000);
            selectOption.text = `${typeString} | ${dayOfWeek}. ${month} ${getOrdinalSuffix(dayOfMonth)} | ${time}`;

            this.requestOptions.push(selectOption);
        });

        this.loading.requests = false;
    },
    watch: {
        participantData(updated) {
            if (!updated || !this.flags['pause-direct-requests']) {
                return;
            }

            const { directRequestsActive } = updated;

            // handle null or undefined differently from false
            if ([null, undefined].includes(directRequestsActive)) {
                return;
            }

            if (!directRequestsActive) {
                if (this.connectorId) {
                    this.$router.push(`/connector/${this.connectorId}`);
                } else {
                    this.$router.push('/');
                }
            }
        },
    },
};
</script>

<style lang="stylus">
.direct-request {
    &__description-link {
        link();
    }

    &__participant-card {
        padding-inline-end: $nebula-space-6x;
        width: auto;
    }

    &__participant-wrapper {
        margin-block-start: $nebula-space-3x;
    }

    &__participant-loader {
        height: 100px;
    }

    &__select-wrapper {
        margin-block-start: $nebula-space-3x;
    }

    &__actions {
        margin-block-start: $nebula-space-4x;
        gap: $nebula-space-2x;

        @media (min-width: $nebula-breakpoints-tablet-portrait) {
            display: flex;
            justify-content: flex-end;
        }
    }
    &__select-loader {
        height: 50px;
    }
    &__selected-details {
        card-base();
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        margin-block-start: $nebula-space-3x;
        padding: $nebula-space-3x;
    }
    &__selected-type {
        margin: 0 0 $nebula-space-half;
        nebula-text-responsive-h6();
    }
    &__selected-name {
        margin: 0;
        nebula-text-responsive-body();
    }
    &__selected-subhead {
        font-size: $nebula-font-size-body-2;
        font-weight: 700;
        margin-block: $nebula-space-2x;
    }
    &__details-button {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-block-start: $nebula-space-2x;
    }
}
</style>
