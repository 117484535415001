<template>
    <PageWrapper class="request-details" pageTitle="Request Details" breadcrumbs>
        <template v-slot:banner>
            <NebulaBlockMessage
                v-if="scheduleSuccessful"
                class="request-details__block-message"
                icon="circle-check"
                color="green"
                header="Your Connect is scheduled!"
                description="You can now connect with the Connector via messaging to ask questions and learn more about the Connect."
            />
        </template>
        <template v-slot:actions>
            <NebulaButton
                v-if="showCancel"
                class="request-details__cancel-button"
                type="flat"
                text="Cancel"
                @click="handleCancelRequest"
            />
            <NebulaButton
                v-if="showEdit"
                icon-left="edit"
                type="ghost"
                text="Edit"
                routerLink
                :link="`/request/${request.requestId}/edit/`"
            />
        </template>
        <NebulaGridRow type="flex">
            <NebulaColumn :columns="12">
                <GrayBoxLoader v-if="loading.request" card />
                <RequestDetailCard
                    v-else-if="request"
                    :request="request"
                >
                    <template v-slot:actions>
                        <ParticipantCard
                            v-if="!loading.applicants && showParticipant"
                            class="request-detail-card__participant-card"
                            :request="request"
                            :pendingParticipant="showPendingParticipant ? directRequestedApplicant : null"
                        >
                            <NebulaButton
                                v-if="showPendingParticipant"
                                class="request-details__requested-button"
                                text="Requested"
                                size="l"
                                :isDisabled="true"
                                type="ghost"
                            />
                        </ParticipantCard>
                    </template>
                </RequestDetailCard>
            </NebulaColumn>
        </NebulaGridRow>
        <NebulaGridRow v-if="showApplicants" type="flex">
            <NebulaColumn :columns="12">
                <PageSection title="Applicants">
                    <div v-if="applicants && applicants.length > 0">
                        <NebulaGridRow gutter>
                            <NebulaGridBlock :columns="3" v-for="(applicant, idx) in applicants" :key="`applicant-${idx}`">
                                <CardProfile :data="applicant">
                                    <template v-slot:actions>
                                        <NebulaButton
                                            size="l"
                                            class="request-details__card-action-button"
                                            text="Decline"
                                            type="ghost"
                                            @click="handleDeclineApplicant(applicant)"
                                        />
                                        <NebulaButton
                                            size="l"
                                            class="request-details__card-action-button"
                                            text="Approve"
                                            @click="handleAcceptApplicant(applicant)"
                                        />
                                    </template>
                                </CardProfile>
                            </NebulaGridBlock>
                        </NebulaGridRow>
                    </div>
                    <div v-else>
                        <ApplicantEmptyState />
                    </div>
                </PageSection>
            </NebulaColumn>
        </NebulaGridRow>
        <NebulaGridRow type="flex">
            <NebulaColumn :columns="12">
                <PageSection class="request-details__detail-section">
                    <RequestContent :loading="loading.request" />
                </PageSection>
            </NebulaColumn>
        </NebulaGridRow>
        <NebulaGridRow v-if="showMatches" type="flex">
            <NebulaColumn :columns="12">
                <PageSection title="Suggested Matches">
                    <GrayBoxLoader v-if="loading.matches" cardRow />
                    <NebulaGridRow v-else gutter>
                        <NebulaGridBlock :columns="3" v-for="(match, idx) in matches" :key="`match-${idx}`">
                            <CardProfile :data="match">
                                <template v-slot:actions>
                                    <NebulaButton
                                        :isDisabled="disableRequestButton(match)"
                                        class="request-details__request-button"
                                        size="l"
                                        :text="requestSubmitText(match)"
                                        type="ghost"
                                        @click="sendDirectRequest(match)"
                                    />
                                </template>
                            </CardProfile>
                        </NebulaGridBlock>
                    </NebulaGridRow>
                </PageSection>
            </NebulaColumn>
        </NebulaGridRow>
        <NebulaGridRow type="flex">
            <NebulaColumn :columns="12">
                <UploadSection
                    :requestId="requestId"
                    :requestOwnerId="requestOwnerId"
                />
            </NebulaColumn>
        </NebulaGridRow>
    </PageWrapper>
</template>

<script>
import {
    NebulaButton,
    NebulaGrid,
    NebulaBlockMessage,
} from '@discoveryedu/nebula-components';
import PageWrapper from '@/components/shared/layout/PageWrapper.vue';
import { RequestDetailCard, RequestContent } from '@/components/shared/requests';
import GrayBoxLoader from '@/components/shared/Loader/GrayBox.vue';
import PageSection from '@/components/shared/layout/PageSection.vue';
import ApplicantEmptyState from '@/components/shared/requests/ApplicantEmptyState.vue';
import CardProfile from '@/components/shared/cards/CardProfile.vue';
import ParticipantCard from '@/components/shared/ParticipantCard.vue';
import UploadSection from '@/components/resources/UploadSection.vue';

import createPayload from '@/mixins/data/createPayload';
import requests from '@/mixins/requests';
import scrollToTop from '@/mixins/scrollToTop';

import { timestampNow } from '@/data/placeholder/date';
import {
    actionRequired,
    pendingMatches,
    awaitingConfirmation,
    futureStatuses,
    requestableStatuses,
} from '@/constants';

// statuses that are possible when there is a pending direct request
const directRequestStatuses = [awaitingConfirmation, actionRequired];

export default {
    name: 'EducatorRequestDetails',
    components: {
        ApplicantEmptyState,
        CardProfile,
        GrayBoxLoader,
        NebulaButton,
        NebulaGridBlock: NebulaGrid.NebulaGridBlock,
        NebulaGridRow: NebulaGrid.NebulaGridRow,
        NebulaColumn: NebulaGrid.NebulaColumn,
        PageWrapper,
        RequestContent,
        RequestDetailCard,
        PageSection,
        ParticipantCard,
        UploadSection,
        NebulaBlockMessage,
    },
    mixins: [createPayload, requests, scrollToTop],
    data() {
        return {
            attachments: [],
            loading: {
                attachments: true,
            },
        };
    },
    computed: {
        scheduleSuccessful() {
            return this.$store.getters.applicationAccept === 'success';
        },
        loadingSubmit() {
            return this.$store.state.status.request_direct_submit.loading;
        },
        showApplicants() {
            const applicationsRelevant = [actionRequired, pendingMatches];

            const now = timestampNow();

            if (now > this.request.startDateTime) {
                return false;
            }
            return !!applicationsRelevant.includes(this.status);
        },
        request() {
            return this.$store.state.page.request;
        },
        matches() {
            return this.$store.state.page.request_matches;
        },
        applicants() {
            return this.$store.state.page.applicants;
        },
        showCancel() {
            return !!futureStatuses.includes(this.status);
        },
        showEdit() {
            // wait for a present request, exclude cancelled requests
            return futureStatuses.includes(this.status);
        },
        showParticipant() {
            return !!this.request.employeeId || this.directRequestedApplicant;
        },
        showMatches() {
            if (this.directRequestedApplicant && directRequestStatuses.includes(this.request.status)) {
                return false;
            }

            return !!this.requestOpen;
        },
        showPendingParticipant() {
            if (!this.directRequestedApplicant) {
                return false;
            }

            // statuses that are possible when there is a pending direct request
            return !!directRequestStatuses.includes(this.request.status);
        },
        status() {
            return this.request.status;
        },
        directRequestedApplicant() {
            return this.$store.state.page.direct_requested_applicant;
        },
        requestOpen() {
            if (!this.request) {
                return false;
            }
            return requestableStatuses.includes(this.request.status);
        },
        requestOwnerId() {
            return this.$store.getters.request.educatorId;
        },
    },
    methods: {
        updateAttachment(payload) {
            this.$store.dispatch('updateRequestAttachment', payload);
        },
        async handleCancelRequest() {
            const { requestId, status: requestStatus } = this.request;
            this.$store.dispatch('toggleModal', {
                category: 'request',
                type: 'cancel',
                show: !this.$store.getters.requestCancelModal.show,
                requestId,
                requestStatus,
            });
        },
        async handleAcceptApplicant(applicant) {
            const { employeeId } = applicant;
            const { requestId, startDateTime } = this.request;

            this.$store.dispatch('toggleModal', {
                category: 'request',
                type: 'approve',
                show: !this.$store.getters.requestApproveModal.show,
                requestId,
                employeeId,
                startDateTime,
            });
        },
        async handleDeclineApplicant(applicant) {
            const { employeeId } = applicant;
            const { requestId } = this.request;

            this.$store.dispatch('toggleModal', {
                category: 'request',
                type: 'decline',
                show: !this.$store.getters.requestDeclineModal.show,
                requestId,
                employeeId,
            });
        },
        disableRequestButton() {
            if (this.loadingSubmit) {
                return true;
            }
            return false;
        },
        async sendDirectRequest(connector) {
            const { employeeId } = connector;
            this.requestedEmployeeId = employeeId;
            this.$store.dispatch('updateLoading', { key: 'request_direct_submit', status: true });

            const payload = await this.createPayload({
                employeeId,
                requestId: this.requestId,
            });

            this.loading.request = true;
            this.loading.applicants = true;

            await this.$store.dispatch('submitDirectRequest', payload);

            const statusPayload = await this.createPayload({ status: awaitingConfirmation, requestId: this.requestId });
            await this.$store.dispatch('updateRequest', statusPayload);

            this.scrollToTop(true);
            await this.getSingleRequest();

            const { status: requestStatus } = this.request;
            const applicantsPayload = await this.createPayload({ requestId: this.requestId, requestStatus });
            await this.$store.dispatch('getApplicants', applicantsPayload);

            this.loading.applicants = false;
            this.requestedEmployeeId = null;
            this.loading.request = false;

            this.$store.dispatch('updateLoading', { key: 'request_direct_submit', status: false });
        },
        requestSubmitText(connector) {
            const { employeeId } = connector;

            const processingConnector = employeeId === this.requestedEmployeeId;

            return processingConnector ? 'Processing' : 'Request';
        },
        async update() {
            await this.getSingleRequest();
            const { requestId, status: requestStatus } = this.$store.state.page.request;
            const payload = await this.createPayload({ requestId, requestStatus });

            await this.$store.dispatch('getApplicants', payload);
            this.loading.applicants = false;

            await this.$store.dispatch('getConnectorMatchesTemp', payload);
            this.loading.matches = false;
        },
    },
    async mounted() {
        this.update();
    },
    async beforeDestroy() {
        await this.$store.dispatch('updateResults', { key: 'application_accept', status: null });
    },
};
</script>

<style lang="stylus">
.request-details {
    &__requested-button {
        width: 100%;
        display: flex;
        justify-content: center;
    }
}

.educator-request-details {
    &__attachment-loader {
        height: 80px;
        margin-block-start: $nebula-space-2x;
    }
}
</style>
