<template>
    <PageWrapper>
        <EmptyState
            v-if="!loading.requests && requests.length < 1"
            class="messages__empty-state"
            image="listening"
            title="No Messages Yet"
            description="Looks like you don't have any scheduled Connects yet"
            :button="{
                text: 'Return to Dashboard',
                link: '/',
            }"
            card
        />

        <NebulaGridRow v-else-if="!loading.requests" class="messages__container" :style="{ gridTemplateRows: this.maxHeight }">
            <NebulaGridBlock
                :columns="4"
                :mdSize="4"
                :smSize="4"
                class="messages__section messages__request-list-section"
            >
                <SectionTitle class="messages__title" title="Messages" />
                <div class="messages__request-list">
                    <MessageSelectButton
                        v-for="(request, idx) in requests"
                        :key="`request-select-${idx}`"
                        :partner="request.partner"
                        :title="request.title"
                        :clickHandler="setActiveMessage"
                        :active="request.requestId === activeChat"
                        :requestId="request.requestId"
                    />
                </div>
            </NebulaGridBlock>
            <NebulaGridBlock :columns="5" :mdSize="4" :smSize="4" class="messages__message-column">
                <div class="messages__thread-wrapper">
                    <MessageThread
                        :loading="loading.messages"
                        :data="messageHistory"
                        :self="selfDisplay"
                        :partner="activeRequest.partner"
                        :requestType="activeRequest.type"
                    />
                    <NebulaInputWithButtons class="messages__send-input" borderRadiusSmall>
                        <template v-slot:input>
                            <GrayBoxLoader v-if="loading.sending" />
                            <NebulaTextarea
                                v-else
                                @input="validate('message')"
                                @keydown.native="handleKeydown"
                                class="messages__textarea"
                                :placeholder="$t('Message')"
                                :maxlength="formData.message.maxLength"
                                v-model="formData.message.value"
                            />
                            <InputLimitHelper>{{ charsLeft }}/{{ formData.message.maxLength }}</InputLimitHelper>
                        </template>
                        <template v-slot:buttonElementEnd>
                            <NebulaButton
                                class="messages__send-button"
                                :isDisabled="disableSendButton"
                                type="ghost"
                                text="Send"
                                @click="publishMessage"
                                iconRight="send"
                            />
                        </template>
                    </NebulaInputWithButtons>
                </div>
            </NebulaGridBlock>
            <NebulaGridBlock :columns="3" :mdSize="8" :smSize="4" class="messages__section messages__request-details">
                <MessageRequestDetails :loading="loading.requests" :request="activeRequest" />
            </NebulaGridBlock>
        </NebulaGridRow>
    </PageWrapper>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import Ably from 'ably';
import {
    NebulaButton,
    NebulaInputWithButtons,
    NebulaGrid,
    NebulaTextarea,
} from '@discoveryedu/nebula-components';

import PageWrapper from '@/components/shared/layout/PageWrapper.vue';
import GrayBoxLoader from '@/components/shared/Loader/GrayBox.vue';
import SectionTitle from '@/components/shared/tokens/SectionTitle.vue';
import { MessageThread, MessageRequestDetails, MessageSelectButton } from '@/components/messaging';
import EmptyState from '@/components/shared/layout/EmptyState.vue';
import InputLimitHelper from '@/components/shared/forms/InputLimitHelper.vue';

import createPayload from '@/mixins/data/createPayload';
import explicitHeight from '@/mixins/explicitHeight';

// import {
//     inXdays,
// } from '@/data/placeholder/date';
import { displayName as getDisplayName } from '@/utils/data/display';

import {
    roles,
} from '@/constants';

const MESSAGE_MAX_LENGTH = 1200;

export default {
    name: 'Message',
    components: {
        EmptyState,
        InputLimitHelper,
        NebulaButton,
        NebulaInputWithButtons,
        NebulaTextarea,
        NebulaGridBlock: NebulaGrid.NebulaGridBlock,
        NebulaGridRow: NebulaGrid.NebulaGridRow,
        PageWrapper,
        SectionTitle,
        GrayBoxLoader,
        MessageThread,
        MessageRequestDetails,
        MessageSelectButton,
    },
    mixins: [createPayload, explicitHeight],
    data() {
        return {
            ably: null,
            channel: null,
            messages: [],
            requestId: null,
            tokenRequest: null,
            requestIds: [],
            activeChat: null,
            activeRequest: {},
            formData: {
                message: {
                    value: '',
                    maxLength: MESSAGE_MAX_LENGTH,
                },
            },
            requests: [],
            loading: {
                requests: true,
                messages: true,
                sending: false,
            },
            showMessage: false,
            charsLeft: MESSAGE_MAX_LENGTH,
        };
    },
    computed: {
        appMode() {
            return this.$store.state.app.mode;
        },
        userId() {
            return this.$store.getters.userId;
        },
        messageHistory() {
            return this.$store.state.page.message;
        },
        days() {
            return Object.keys(this.messageHistory);
        },
        selfDisplay() {
            return this.$store.getters.userDisplayData;
        },
        disableSendButton() {
            if (!this.formData.message.value || this.formData.message.value.length < 1) {
                return true;
            }
            if (this.loading.sending) {
                return true;
            }
            return false;
        },
    },
    async mounted() {
        const { id: paramId } = this.$route.query;

        // fetch all requests eligible for messaging
        const payload = await this.createPayload({ key: 'messages' });
        await this.$store.dispatch('getMessageRequests', payload);

        this.requests = this.$store.state.page.messages.map((each) => ({
            ...each,
            partner: this.getPartner(each),
        }));

        this.$store.state.page.messages.forEach((each) => {
            this.requestIds.push(each.requestId);
        });

        this.loading.requests = false;

        if (this.requests.length < 1) {
            return;
        }
        // if path contains an eligible request id, prioritize that
        if (paramId && this.requestIds.includes(paramId)) {
            this.activeChat = paramId;
        } else {
            const [firstRequest] = this.requestIds;
            this.activeChat = firstRequest;
        }

        this.activeRequest = this.requests.find((request) => request.requestId === this.activeChat);
        this.setActiveMessage(this.activeChat);

        await this.initializeAbly();
        const { ably } = this;

        this.openChannel(this.activeChat);

        await ably.connection.once('connected');

        this.getMessages();
    },
    methods: {
        enforceCharLimit(formKey) {
            const { value, maxLength } = this.formData[formKey];
            // if value goes over the char limit, don't update
            if (value.length > maxLength) {
                this.formData[formKey].value = value.slice(0, maxLength);
            } else {
                // otherwise calculate the characters left
                this.charsLeft = maxLength - value.length;
            }
        },
        async initializeAbly() {
            this.ably = await new Ably.Realtime.Promise({
                authCallback: async (tokenParams, callback) => {
                    try {
                        const tokenRequest = await this.createTokenRequest();
                        callback(null, tokenRequest);
                    } catch (error) {
                        callback(error, null);
                    }
                },
            });
        },
        async getMessages() {
            const messagePayload = await this.createPayload({ requestId: this.activeChat });
            return this.$store.dispatch('getMessages', messagePayload);
        },
        async openChannel(requestId) {
            this.channel = await this.ably.channels.get(`messaging:${requestId}`);
            await this.channel.subscribe('message', () => {
                this.getMessages();
            });
        },
        async publishMessage() {
            this.loading.sending = true;
            const message = this.formData.message.value;
            const payload = await this.createPayload({
                message,
                requestId: this.activeChat,
            });

            await this.$store.dispatch('sendMessage', payload);
            this.getMessages();

            await this.channel.publish('message', 'new');

            this.charsLeft = MESSAGE_MAX_LENGTH;
            this.formData.message.value = '';
            this.loading.sending = false;
        },
        async createTokenRequest() {
            const { requestId } = this.activeRequest;
            const payload = await this.createPayload({ requestId, interim: true });
            return this.$store.dispatch('getAblyTokenRequest', payload);
        },
        async getToken() {
            const { tokenRequest } = this;
            return this.$store.dispatch('getToken', { tokenRequest });
        },
        async setActiveMessage(requestId) {
            this.loading.messages = true;

            const { id: paramId } = this.$route.query;
            const { activeRequestId } = this.activeRequest;
            if (this.activeChat === requestId && activeRequestId === requestId) {
                return;
            }

            this.activeChat = requestId;
            this.activeRequest = this.requests.find((request) => request.requestId === this.activeChat);

            await this.getMessages();

            // const href = new URL(window.location.href);
            // href.searchParams.set('');
            if (paramId !== requestId) {
                const params = new URLSearchParams(window.location.search);
                params.set('id', requestId);
                const { origin, pathname } = window.location;
                const newUrl = `${origin}${pathname}?${params.toString()}`;
                window.history.pushState(
                    {},
                    null,
                    newUrl,
                );
            }

            this.loading.messages = false;
        },
        handleKeydown(e) {
            if (e.code === 'Enter') {
                if (!e.shiftKey) {
                    e.preventDefault();
                    this.publishMessage();
                }
            }
        },
        getPartner(request) {
            let partnerPrefix;
            let group;
            if (this.appMode === roles.EDUCATOR_ROLE) {
                partnerPrefix = 'employee';
            } else {
                partnerPrefix = 'educator';
            }

            const partnerObj = {};

            // define keys to be pulled out of request object
            const keysToCapture = [
                'FirstName',
                'LastName',
                'PreferredName',
                'AvatarUrl',
                'Id',
            ];

            keysToCapture.forEach((key) => {
                const newKey = key[0].toLowerCase() + key.slice(1);
                partnerObj[newKey] = request[`${partnerPrefix}${key}`];
            });

            const displayName = getDisplayName(partnerObj);
            const { avatarUrl, id } = partnerObj;

            if (partnerPrefix === 'employee') {
                group = request.organizations[0].organizationName;
            }

            return {
                avatarUrl,
                id,
                displayName,
                group,
            };
        },
        validate(formKey) {
            this.enforceCharLimit(formKey);
        },
    },
    watch: {
        requests(updated, prev) {
            if (prev) {
                return;
            }

            this.requests = this.requests.map((request) => {
                let { educatorAvatarUrl: avatarUrl } = request;
                if (this.appMode === roles.EDUCATOR_ROLE) {
                    avatarUrl = request.employeeAvatarUrl;
                }

                const partner = {
                    avatarUrl,
                };

                return {
                    ...request,
                    ...partner,
                };
            });
        },
    },
    beforeDestroy() {
        this.ably.close();
        this.$store.dispatch('resetPageData');
    },
};
</script>

<style lang="stylus">
.messages {
    &__container {
        width: 100%;
        height: 100%;
        card-base();
        z-index: 0;
    }

    &__list {
        padding: $nebula-space-3x;

        h2 {
            margin: 0;
        }
    }

    &__send-input {
        width: 100%;
        align-self: flex-end;

        .nebula-input-with-buttons__input {
            width: 100%;
        }
    }

    &__send-button {
        height: 92px;
    }

    &__title {
        margin-block-end: $nebula-space-3x;
    }

    &__textarea {
        resize: none;
        height: 92px;
        min-height: 92px;
        padding: $nebula-space-1x $nebula-space-2x;
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
        border-right: none;
        margin-bottom: 0;
    }

    &__message-column {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        position: relative;
        padding-block-start: $nebula-space-3x;
        border-inline: 1px solid $nebula-color-platform-interface-400;
    }

    &__thread-wrapper {
        display: flex;
        flex: 1;
        flex-direction: column;
        height: 100%;
    }

    &__request-list {
        height: 100%;
        overflow-y: auto;
    }

    &__request-list-section {
        display: flex;
        flex-direction: column;
        padding-block-start: $nebula-space-3x;
        padding-inline-end: $nebula-space-1x;
        padding-inline-start: $nebula-space-3x;
    }

    &__section {
        background: $nebula-color-platform-white;
        position: relative;
        z-index: 1;
    }

    &__request-details {
        padding: $nebula-space-3x;
        display: flex;
        flex-direction: column;
    }

    &__send-input {
        box-shadow: $nebula-shadow-400;
        padding: $nebula-space-3x $nebula-space-3x $nebula-space-1x;
    }

    &__select {
        padding-block: $nebula-space-3x;
        display: flex;
        background: transparent;
        border: none;
        text-align: left;
        align-items: center;
        width: 100%;
        border-bottom: 1px solid $nebula-color-platform-interface-400;
        transition: background $nebula-transition-default;
        border-inline-start: 3px solid transparent;

        &--active {
            background: $nebula-color-platform-interactive-100;
            border-inline-start: 3px solid $nebula-color-platform-interactive-800;
        }
        &>div {
            margin-left: $nebula-space-1x;
        }
    }

    &__request-title, &__message-partner-name, &__message-partner-group {
        margin: 0 0 $nebula-space-half;
    }

    &__request-title {
        nebula-text-body-2();
        font-weight: 600;
        color: $nebula-color-platform-neutral-900;
    }

    &__message-partner-name {
        color: $nebula-color-platform-neutral-700;
        font-size: $font-size-caption;
        font-weight: 600;
    }

    &__message-partner-group {
        color: $nebula-color-platform-neutral-700;
        font-size: $font-size-caption;
        font-weight: 400;
    }

    &__unread-indicator {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        &--active {
            background: $nebula-color-platform-interactive-800;
        }
    }
    &__empty-state {
        .empty-state__content {
            flex-direction: column;
            align-items: center;
            padding: $nebula-space-5x;
        }
        .empty-state__image-area {
            margin-bottom: $nebula-space-3x;
        }
    }
}
</style>
