<template>
    <PageWrapper breadcrumbs pageTitle="Organizations">
        <NebulaGridRow type="flex" class="browse-orgs__filter-row">
            <FilterPopover @close="handleClosePopover" @clear-all-clicked="clearAllFilters">
                <FilterList
                    :items="industryOptions"
                    :ref="filterOptions.industry.ref"
                    :selected="filters.industry"
                    topic="industry"
                    title="Industry"
                    @checkbox-toggled="handleFilter"
                />
            </FilterPopover>
            <NebulaVueSelect
                class="browse-orgs__sort-dropdown"
                :clearable="false"
                :is-searchable="false"
                :options="options"
                placeholder="Sort by"
                size="s"
                @change-selected="handleSort"
            />
        </NebulaGridRow>
        <NebulaGridRow gutter>
            <NebulaGridBlock :columns="3" v-for="(organization, idx) in results" :key="idx">
                <CardProfile
                    v-if="organization"
                    :data="organization"
                    buttonText="View Profile"
                    :buttonLink="`organization/${organization.organizationId}`"
                />
            </NebulaGridBlock>
        </NebulaGridRow>
        <NebulaGridRow v-if="!loading && results.length < 1">
            <NebulaGridBlock :columns="12">
                <EmptySearch />
            </NebulaGridBlock>
        </NebulaGridRow>
        <PaginationRow
            v-if="paging"
            :paging="paging"
            :pagination="pagination"
            :resultsLength="results.length"
            :updatePagination="updatePagination"
            @select-page="getResults"
        />
    </PageWrapper>
</template>

<script>
import {
    NebulaGrid,
    NebulaVueSelect,
} from '@discoveryedu/nebula-components';
import FilterPopover from '@/components/shared/tokens/FilterPopover.vue';
import CardProfile from '@/components/shared/cards/CardProfile.vue';
import createPayload from '@/mixins/data/createPayload';
import {
    industryOptions,
} from '@/data/formData';
import FilterList from '@/components/shared/tokens/FilterList.vue';
import EmptySearch from '@/components/browse/EmptySearch.vue';
import PaginationRow from '@/components/browse/PaginationRow.vue';
import PageWrapper from '@/components/shared/layout/PageWrapper.vue';

import scrollToTop from '@/mixins/scrollToTop';
import { browse, pagination } from '@/mixins/browse';

export default {
    name: 'BrowseOrganizations',
    components: {
        EmptySearch,
        NebulaGridRow: NebulaGrid.NebulaGridRow,
        NebulaGridBlock: NebulaGrid.NebulaGridBlock,
        PageWrapper,
        FilterPopover,
        NebulaVueSelect,
        CardProfile,
        FilterList,
        PaginationRow,
    },
    mixins: [createPayload, scrollToTop, pagination, browse],
    computed: {
        industryOptions,
        results() {
            return this.$store.state.page.organizations && this.$store.state.page.organizations;
        },
        breadcrumbs() {
            const fallbackBreadcrumb = {
                dim_text: '',
                link: '/',
                text: 'Dashboard',
            };

            const storedBreadcrumbs = this.$store.state.app.breadcrumb;
            return storedBreadcrumbs.length > 1
                ? storedBreadcrumbs : [fallbackBreadcrumb].concat(storedBreadcrumbs);
        },
    },
    data() {
        return {
            loading: true,
            filterOptions: {
                industry: {
                    ref: 'industryRef',
                },
            },
            filters: {
                tag: [],
                industry: [],
                timeslot: [],
                language: [],
            },
            sort: 'alpha-by-name',
            sortConfig: {
                'alpha-by-name': {
                    order: 'ASC',
                },
                'reverse-alpha-by-name': {
                    order: 'DESC',
                },
                'most-recently-added': {
                    sort: 'createdDate',
                },
            },
            // breadcrumbs: [
            //     {
            //         dim_text: '',
            //         link: '/',
            //         text: 'Parent Page',
            //     },
            //     {
            //         dim_text: '',
            //         link: '/connectors',
            //         text: 'Browse Connectors',
            //     },
            // ],
            options: [
                {
                    value: 'most-recently-added',
                    label: 'Most Recently Added',
                },
                {
                    value: 'alpha-by-name',
                    label: 'Organization (A to Z)',
                },
                {
                    value: 'reverse-alpha-by-name',
                    label: 'Organization (Z to A)',
                },
            ],
        };
    },
    methods: {
        async getResults() {
            const params = this.getCombinedParams().toString();

            const payload = await this.createPayload({ params });
            await this.$store.dispatch('getOrganizations', payload);
            this.loading = false;
        },
        handleClosePopover() {
            Object.keys(this.filterOptions).forEach((key) => {
                const ref = this.$refs[this.filterOptions[key].ref];
                if (ref) {
                    ref.collapse();
                }
            });
        },
        clearAllFilters() {
            const excluded = [];
            const filters = Object.keys(this.filters).filter((key) => !excluded.includes(key));
            filters.forEach((filter) => {
                this.filters[filter] = [];
            });
            this.getResults();
        },
    },
    async mounted() {
        this.scrollToTop();
        this.applyPageSettings();

        await this.getResults();
        this.initializePagination();
    },
};
</script>

<style lang="stylus">
.browse-orgs {
    &__filter-row {
        gap: $nebula-space-2x;
        display: flex;
        align-items: center;
        margin-bottom: $nebula-space-4x;
        @media (min-width: $nebula-breakpoints-tablet-portrait) {
            display: flex;
            justify-content: flex-end;
            margin-bottom: $nebula-space-5x;
        }
    }

    &__filter-accordion {
        width: 265px;
        .nebula-checkbox__container {
            width: 100%;
        }
        .nebula-checkbox__text {
            line-height: 1em;
        }
    }

    &__sort-dropdown {
        --nebula-vs-dropdown-toggle-border: 1px solid $nebula-color-platform-interface-400;
        margin: 0;
        width: 100%;
        @media (min-width: $nebula-breakpoints-tablet-portrait) {
            width: 235px;
        }
    }

    &__availability-accordion {
        .nebula-accordion__content-container {
            // --nebula-accordion-padding: 0;
            --nebula-date-picker-calendar-border: none;
        }
        .nebula-date-picker__calendar>div {
            width: 100%;
        }
    }

    &__datepicker {
        margin: -16px -16px 0 -16px;
        --nebula-date-picker-caret-size: 24px;
        --nebula-date-picker-calendar-date-border: 2px solid #ffffff;
        --nebula-icon-size-s: 12px;

        .nebula-date-picker__calendar {
            width: 100%;
            & .picker-view .vdp-datepicker__up {
                margin-left: 0;
            }
            .picker-view .cell {
                height: 33px;
            }
        }
        &:after {
            content: '';
            display: block;
            height: 1px;
            background: $nebula-color-platform-interface-300;
            margin-inline: $nebula-space-2x;
            margin-bottom: $nebula-space-4x;
        }
    }
}
</style>
