<template>
    <PageWrapper breadcrumbs :pageTitle="pageHeader">
        <NebulaGridRow type="flex" class="browse__filter-select-row">
            <NebulaColumn :columns="8" :mdSize="8" style="display: flex;">
                <p>Showing {{ filteredConnectors }} out of {{ totalConnectors }} Career Connect professionals.</p>
            </NebulaColumn>
            <NebulaColumn :columns="4" :mdSize="4" class="browse__filters">
                <FilterPopover :numberOfFilters="numberOfFilters" @close="handleClosePopover" @clear-all-clicked="clearAllFilters">
                    <FilterList
                        :items="tagOptions"
                        :ref="filterOptions.subject.ref"
                        :selected="filters.tag"
                        topic="tag"
                        title="Areas of Expertise"
                        @checkbox-toggled="handleFilter"
                    />
                    <FilterList
                        :items="industryOptions"
                        :ref="filterOptions.industry.ref"
                        :selected="filters.industry"
                        topic="industry"
                        title="Industry"
                        @checkbox-toggled="handleFilter"
                    />
                    <FilterList
                        :items="languageOptions"
                        :ref="filterOptions.language.ref"
                        :selected="filters.language"
                        topic="language"
                        title="Spoken Languages"
                        @checkbox-toggled="handleFilter"
                    />
                    <FilterList
                        v-if="!flags['calendar-removed']"
                        :items="timeSlotOptions"
                        :ref="filterOptions.timeslot.ref"
                        :selected="filters.timeslot_selected"
                        topic="timeslot"
                        title="Availability"
                        class="browse__availability-accordion"
                        :isDisabled="filters.date.length < 1"
                        @checkbox-toggled="handleTimeslotFilter"
                    >
                        <BrowseDatePicker
                            :selected="filters.date[0] && new Date(filters.date[0])"
                            @date-selected="handleDate"
                        />
                    </FilterList>
                </FilterPopover>
                <NebulaVueSelect
                    class="browse__sort-dropdown"
                    :clearable="false"
                    :is-searchable="false"
                    :options="options"
                    placeholder="Sort by"
                    size="s"
                    @change-selected="handleSort"
                />
            </NebulaColumn>
        </NebulaGridRow>
        <NebulaGridRow type="flex" class="browse__filter-row">
            <div style="display: flex; gap: 8px; width: 100%; flex-wrap: wrap;">
                <NebulaTag v-for="(each, idx) in filters.tag"
                    :key="`tag-${idx}`"
                    :text="tagOptions.reduce((acc, tag) => (tag.value === each ? tag.text : acc), '')"
                    @click="clearTagFilter(each, 'tag')"
                />
                <NebulaTag
                    v-for="(each, idx) in filters.industry"
                    :key="`industry-${idx}`"
                    :text="industryOptions.reduce((acc, opt) => (opt.value === each ? (opt.text || opt.label) : acc), '')"
                    @click="clearTagFilter(each, 'industry')"
                />
                <NebulaTag v-for="(each, idx) in filters.language"
                    :key="`language-${idx}`"
                    :text="languageOptions.reduce(
                        (acc, lang) => (lang.value.toLowerCase() === each.toLowerCase() ? (lang.text || lang.label) : acc),
                        ''
                    )"
                    @click="clearTagFilter(each, 'language')"
                />
            </div>
        </NebulaGridRow>
        <NebulaGridRow v-if="!loading && results.length < 1">
            <NebulaGridBlock :columns="12">
                <EmptySearch />
            </NebulaGridBlock>
        </NebulaGridRow>
        <NebulaGridRow v-else gutter>
            <NebulaGridBlock :columns="3" :mdSize="4" :smSize="4" v-for="(connector, idx) in results" :key="idx">
                <CardProfile
                    :data="connector"
                    buttonText="View Profile"
                    :buttonLink="`connector/${connector.employeeId}`"
                />
            </NebulaGridBlock>
        </NebulaGridRow>
        <PaginationRow
            v-if="paging"
            :paging="paging"
            :pagination="pagination"
            :loading="loading"
            :resultsLength="results.length"
            :updatePagination="updatePagination"
            @select-page="getResults"
        />
    </PageWrapper>
</template>

<script>
import {
    NebulaGrid,
    NebulaTag,
    NebulaVueSelect,
} from '@discoveryedu/nebula-components';
import FilterPopover from '@/components/shared/tokens/FilterPopover.vue';
import CardProfile from '@/components/shared/cards/CardProfile.vue';
import EmptySearch from '@/components/browse/EmptySearch.vue';
import createPayload from '@/mixins/data/createPayload';
import PaginationRow from '@/components/browse/PaginationRow.vue';
import BrowseDatePicker from '@/components/shared/requests/BrowseDatePicker.vue';
import { browse, pagination } from '@/mixins/browse';
import { cleanDate, convertToSeconds } from '@/data/placeholder/date';
import PageWrapper from '@/components/shared/layout/PageWrapper.vue';

import scrollToTop from '@/mixins/scrollToTop';

import {
    tagOptions,
    industryOptions,
    languageOptions,
    timeSlotOptions,
} from '@/data/formData';
import timeSlotsMap from '@/data/timeSlotsMap';

import FilterList from '@/components/shared/tokens/FilterList.vue';

export default {
    name: 'BrowseConnectors',
    components: {
        NebulaGridRow: NebulaGrid.NebulaGridRow,
        NebulaGridBlock: NebulaGrid.NebulaGridBlock,
        NebulaColumn: NebulaGrid.NebulaColumn,
        NebulaTag,
        NebulaVueSelect,
        BrowseDatePicker,
        FilterPopover,
        CardProfile,
        FilterList,
        EmptySearch,
        PaginationRow,
        PageWrapper,
    },
    mixins: [createPayload, scrollToTop, pagination, browse],
    computed: {
        tagOptions,
        industryOptions,
        languageOptions,
        timeSlotOptions,
        organizationName() {
            return this.$store.state.page.organization[this.orgId].name;
        },
        results() {
            return this.$store.state.page.employees.filter((employee) => employee.isActive);
        },
        breadcrumbs() {
            const fallbackBreadcrumb = {
                dim_text: '',
                link: '/',
                text: 'Dashboard',
            };

            const storedBreadcrumbs = this.$store.state.app.breadcrumb;
            return storedBreadcrumbs.length > 1
                ? storedBreadcrumbs : [fallbackBreadcrumb].concat(storedBreadcrumbs);
        },
        flags() {
            return this.$store.getters.flags;
        },
        numberOfFilters() {
            return Object.keys(this.filters).reduce((acc, key) => acc + this.filters[key].length, 0);
        },
    },
    data() {
        return {
            pageHeader: null,
            loading: true,
            filterOptions: {
                subject: {
                    ref: 'subjectFilter',
                },
                industry: {
                    ref: 'industryFilter',
                },
                language: {
                    ref: 'languageFilter',
                },
                timeslot: {
                    ref: 'timeslotFilter',
                },
            },
            filters: {
                tag: [],
                industry: [],
                dateTimeRange: [],
                language: [],
                organization: [],
                timeslot_selected: [],
                date: [],
            },
            sort: 'alpha-by-name',
            breadcrumbsReady: false,
            orgId: null,
            sortConfig: {
                'alpha-by-name': {
                    order: 'ASC',
                },
                'reverse-alpha-by-name': {
                    order: 'DESC',
                },
                'most-recently-active': {
                    sort: 'dateModified',
                },
            },
            timeSlotsMap,
            options: [
                {
                    value: 'most-recently-active',
                    label: 'Most Recently Active',
                },
                {
                    value: 'alpha-by-name',
                    label: 'Connector Name (A to Z)',
                },
                {
                    value: 'reverse-alpha-by-name',
                    label: 'Connector Name (Z to A)',
                },
            ],
            totalConnectors: null,
            filteredConnectors: null,
        };
    },
    methods: {
        resultsCallback(params) {
            return params;
        },
        clearAllFilters() {
            const excluded = ['organization'];
            const filters = Object.keys(this.filters).filter((key) => !excluded.includes(key));
            filters.forEach((filter) => {
                this.filters[filter] = [];
            });
            this.getResults();
        },
        clearTagFilter(item, filter) {
            const idx = this.filters[filter].findIndex((each) => each === item);
            if (idx >= 0) {
                this.filters[filter].splice(idx, 1);
            }
            this.getResults();
        },
        async getResults() {
            this.loading = true;
            this.scrollToTop();
            const params = this.getCombinedParams().toString();
            const payload = await this.createPayload({ params });
            await this.$store.dispatch('getEmployees', payload);
            this.filteredConnectors = this.$store.state.page.filteredEmployeesCount;
            this.totalConnectors = this.$store.state.page.totalEmployeesCount;
            this.loading = false;
        },
        async handleDate(date) {
            let start;
            let end;
            const updatedTimes = [];

            this.handleFilter({ topic: 'date', value: cleanDate(date).getTime() }, false);

            const existingTimes = this.filters.dateTimeRange;
            if (this.filters.date.length > 0) {
                // reset to be replaced with new date
                this.filters.dateTimeRange = [];
                this.filters.date = this.filters.date.slice(0, 0);
            }

            if (this.filters.timeslot_selected.length < 1) {
                const newDate = cleanDate(date);
                newDate.setHours(6);
                start = convertToSeconds(newDate.getTime());
                newDate.setHours(21);
                end = convertToSeconds(newDate.getTime());

                updatedTimes.push(`${start},${end}`);
            } else {
                // if there's already a selected date and there are times selected

                // on date selection, if times have already been selected, update the filters to be the new date with same times
                existingTimes.forEach((timeString) => {
                    [start, end] = timeString.split(',');

                    start = new Date(parseInt(start, 10) * 1000);
                    end = new Date(parseInt(end, 10) * 1000);

                    const newMonth = date.getMonth();
                    const newDate = date.getDate();
                    const newYear = date.getFullYear();

                    [start, end].forEach((each) => {
                        each.setDate(newDate);
                        each.setMonth(newMonth);
                        each.setFullYear(newYear);
                    });

                    const newStart = start.getTime().toString().slice(0, 10);
                    const newEnd = end.getTime().toString().slice(0, 10);

                    updatedTimes.push(`${newStart},${newEnd}`);
                });
            }

            this.filters.dateTimeRange = updatedTimes;
            await this.getResults();
        },
        async handleTimeslotFilter(data) {
            // convert the value and selected date into a string with the correct time stamps
            const { value } = data;
            const { start, end } = this.timeSlotsMap[value];

            let [date] = this.filters.date;
            if (!date) {
                return;
            }

            // if this is the first timeslot selected, there's a whole-day timeslot in place
            // remove this to get more specific
            if (!this.filters.timeslot_selected.length > 0) {
                this.filters.dateTimeRange = [];
            }

            date = new Date(date);

            const startDateInt = date.setHours(start);
            // go back a full minute to allow for the offsets that are currently happening in the API
            // TODO remove this when the API issue is resolved
            const startDateString = (startDateInt - 60000).toString();

            const endDateInt = date.setHours(end);
            // go forward a full minute to allow for the offsets that are currently happening in the API
            // TODO remove this when the API issue is resolved
            const endDateString = (endDateInt + 60000).toString();

            await this.handleFilter({ topic: 'timeslot_selected', value }, false);
            // truncate timestamp to 10 digits, API expects seconds
            this.handleFilter({ value: `${startDateString.slice(0, 10)},${endDateString.slice(0, 10)}`, topic: 'dateTimeRange' });
        },
        handleClosePopover() {
            Object.keys(this.filterOptions).forEach((key) => {
                const ref = this.$refs[this.filterOptions[key].ref];
                if (ref) {
                    ref.collapse();
                }
            });
        },
    },
    async mounted() {
        this.scrollToTop();
        this.applyPageSettings();

        console.log(this.$store.state.page.filteredEmployeesCount);
        const orgId = this.$route.params.org_id;

        let { tag, language } = this.$route.query;
        if (tag) {
            tag = tag.split(',');

            tag.forEach((eachTag) => {
                this.updateFilter({
                    topic: 'tag',
                    value: eachTag,
                });
            });
        }

        if (language) {
            language = language.split(',');

            language.forEach((eachLang) => {
                this.updateFilter({
                    topic: 'language',
                    value: eachLang,
                });
            });
        }

        if (orgId) {
            this.orgId = orgId;
            this.pageHeader = 'Our Connectors';

            this.updateFilter({
                topic: 'organization',
                value: orgId,
            });

            const orgFetchPayload = await this.createPayload({ organizationId: orgId });
            await this.$store.dispatch('viewOrganization', orgFetchPayload);

            this.$store.dispatch('updateBreadcrumb', {
                dim_text: '',
                link: window.location.pathname,
                text: `${this.organizationName || ''} Connectors`,
            });
            this.breadcrumbsReady = true;
        } else {
            this.pageHeader = 'All Connectors';
            this.breadcrumbsReady = true;
        }

        await this.getResults();
        this.initializePagination();
    },
};
</script>

<style lang="stylus">
.browse {
    &__filter-row {
        display: flex;
        margin-bottom: $nebula-space-4x;
        @media (min-width: $nebula-breakpoints-tablet-portrait) {
            display: flex;
            justify-content: flex-end;
            margin-bottom: $nebula-space-5x;
        }
    }

    &__filter-select-row {
        display: flex;
        @media (min-width: $nebula-breakpoints-tablet-portrait) {
            display: flex;
            justify-content: flex-end;
        }
    }

    &__filters {
        display: flex;
    }

    &__sort-dropdown {
        --nebula-vs-dropdown-toggle-border: 1px solid $nebula-color-platform-interface-400;
        margin: 0;
        width: 100%;
        @media (min-width: $nebula-breakpoints-tablet-portrait) {
            width: 235px;
        }
    }

    &__availability-accordion {
        .nebula-accordion__content-container {
            // --nebula-accordion-padding: 0;
            --nebula-date-picker-calendar-border: none;
        }
        .nebula-date-picker__calendar>div {
            width: 100%;
        }
    }

    &__datepicker {
        margin: -16px -16px 0 -16px;
        --nebula-date-picker-caret-size: 24px;
        --nebula-date-picker-calendar-date-border: 2px solid #ffffff;
        --nebula-icon-size-s: 12px;

        .nebula-date-picker__calendar {
            width: 100%;
            & .picker-view .vdp-datepicker__up {
                margin-left: 0;
            }
            .picker-view .cell {
                height: 33px;
            }
        }
        &:after {
            content: '';
            display: block;
            height: 1px;
            background: $nebula-color-platform-interface-300;
            margin-inline: $nebula-space-2x;
            margin-bottom: $nebula-space-4x;
        }
    }
}
</style>
