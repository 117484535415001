<template>
    <PageWrapper>
        <NebulaGridRow type="flex">
            <NebulaColumn
                :columns="6"
                :offset="3"
                :mdSize="8"
                :mdOffset="0"
            >
                <PageHeader title="Edit Request" />
                <PageSubheader
                    header="Edit Request Details"
                    :description="$t('edit-request-description', { ns: 'requestedit'})"
                />
                <GrayBoxLoader v-if="loading" />
                <RequestForm v-else :data="request" edit />
            </NebulaColumn>
        </NebulaGridRow>
    </PageWrapper>
</template>

<script>
import {
    NebulaGrid,
    // NebulaButton,
} from '@discoveryedu/nebula-components';

import PageHeader from '@/components/shared/PageHeader.vue';
import PageSubheader from '@/components/shared/layout/PageSubheader.vue';
import RequestForm from '@/components/shared/requests/RequestForm.vue';
import GrayBoxLoader from '@/components/shared/Loader/GrayBox.vue';
import PageWrapper from '@/components/shared/layout/PageWrapper.vue';

import validation from '@/mixins/form/validation';
import createPayload from '@/mixins/data/createPayload';

// import { typeOptions, tagOptions, gradeLevelOptions } from '@/data/formData';
// import formatTime from '@/utils/formatTime';

export default {
    name: 'NewRequest',
    components: {
        GrayBoxLoader,
        NebulaColumn: NebulaGrid.NebulaColumn,
        NebulaGridRow: NebulaGrid.NebulaGridRow,
        PageHeader,
        PageWrapper,
        PageSubheader,
        RequestForm,
    },
    mixins: [validation, createPayload],
    computed: {
        request() {
            return this.$store.state.page.request;
        },
    },
    data() {
        return {
            loading: true,
        };
    },
    methods: {
    },
    async mounted() {
        this.requestId = this.$route.params.id;

        this.loading = true;
        const payload = await this.createPayload({ requestId: this.requestId });
        await this.$store.dispatch('getRequests', payload);
        this.loading = false;
    },
};
</script>

<style lang="stylus">
.new-request {
    &__action-row, &__actions {
        @media (min-width: $nebula-breakpoints-tablet-portrait) {
            display: flex;
            justify-content: flex-end;
        }
    }
    &__action-row {
        margin-block-start: $nebula-space-2x;
    }

    &__actions {
        margin-block-start: $nebula-space-4x;
        gap: $nebula-space-2x;
    }

    &__datepicker-label {
        prepop-label();
    }
}
</style>
