<template>
   <NebulaPopover
        ariaLabel="Add to Calendar"
        id="calendarPopover"
        boundries-selector=".nebula-page-wrapper"
        hidePointerNub
        placement="bottom"
    >
        <template v-slot:content>
            <div>
                <NebulaButton
                    @click="exportToGoogleCalendar(request)"
                    type="flat"
                    text="Google Calendar"
                />
                <NebulaButton
                    @click="exportToICal(request)"
                    type="flat"
                    text="Outlook"
                />
                <NebulaButton
                    @click="exportToICal(request)"
                    type="flat"
                    text="iCal"
                />
            </div>
        </template>
        <template v-slot:trigger="{ togglePopover, popoverOpen }">
                <NebulaButton
                    :aria-label="$t('Add to Calendar')"
                    :aria-expanded="popoverOpen && 'true' || 'false'"
                    aria-haspopup="true"
                    icon-left="calendar"
                    icon-right="caret-down"
                    id="nebula-popover-trigger__notificationsPopover"
                    class="nebula-popover__trigger notifications-popover__count"
                    type="primary"
                    text="Add to calendar"
                    @click="togglePopover"
                >
                Add to Calendar
            </NebulaButton>
        </template>
    </NebulaPopover>
</template>

<script>
import { NebulaButton, NebulaPopover } from '@discoveryedu/nebula-components';

export default {
    name: 'AddToCalendarButton',
    props: {
        request: {
            type: Object,
            required: true,
        },
    },
    components: {
        NebulaButton,
        NebulaPopover,
    },
    methods: {
        exportToGoogleCalendar(request) {
            const formatDate = (date) => date.toISOString().replace(/-|:|\.\d+/g, '');

            const start = formatDate(new Date(request.startDateTime * 1000));
            const end = formatDate(new Date(request.endDateTime * 1000));
            const title = encodeURIComponent(request.title);
            const description = encodeURIComponent(request.description || '');
            const location = encodeURIComponent(request.meeting_link || '');
            // eslint-disable-next-line max-len
            const url = `https://www.google.com/calendar/render?action=TEMPLATE&text=${title}&dates=${start}/${end}&details=${description}&location=${location}&sf=true&output=xml`;

            window.open(url, '_blank');
        },

        exportToICal(request) {
            const formatDate = (date) => date.toISOString().replace(/-|:|\.\d+/g, '');

            const start = formatDate(new Date(request.startDateTime * 1000));
            const end = formatDate(new Date(request.endDateTime * 1000));
            const title = request.title || '';
            const description = request.description || '';
            const location = request.meeting_link || '';

            const icsContent = `
BEGIN:VCALENDAR
VERSION:2.0
PRODID:-//Discovery Education//Career Connect//EN
METHOD:REQUEST
BEGIN:VEVENT
UID:${new Date().getTime()}-career-connect
ORGANIZER;CN=Career Connect:mailto:no-reply@careerconnect.com
DTSTAMP:${formatDate(new Date())}
DTSTART:${start}
DTEND:${end}
SUMMARY:${title}
DESCRIPTION:${description}
LOCATION:${location}
END:VEVENT
END:VCALENDAR
            `.trim();

            const blob = new Blob([icsContent], { type: 'text/calendar;charset=utf-8' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = `${title}.ics`;
            link.click();
        },
    },
};
</script>

<style scoped>
</style>
