<template>
    <PageWrapper class="request-directory">
        <NebulaGridRow type="flex">
            <NebulaColumn :columns="12">
                <PageHeader title="Connect Directory">
                    <template v-slot:actions v-if="isEducator">
                        <NebulaButton
                            class="request-directory__create-button"
                            icon-left="clipboard"
                            type="ghost"
                            text="Create a New Request"
                            routerLink
                            link="/request/new"
                            data-click-type="Request Directory: Educator"
                            data-click-name="Create a New Request"
                        />
                    </template>
                </PageHeader>
            </NebulaColumn>
        </NebulaGridRow>
        <NebulaGridRow v-if="primaryTabs && primaryTabs.length > 0" type="flex" class="request-directory__filter-row">
            <NebulaColumn :columns="12" :mdSize="8" class="request-directory__tabs-primary">
                <NebulaTabs
                    :aria-label="$t('link-navigation-aria', { ns: 'requestdirectory' })"
                    class="request-directory__tabs-navigation"
                >
                    <template v-slot:tabs>
                        <NebulaTabItem
                            v-for="(tab, index) in primaryTabs"
                            :key="index"
                            :tab="tab"
                            :selected="tabActive[1] === index"
                            @click.native="handleTabClick(tab, index)"
                        />
                    </template>
                </NebulaTabs>
            </NebulaColumn>
        </NebulaGridRow>
        <NebulaGridRow type="flex" class="request-directory__filter-row">
            <NebulaColumn :columns="6" :mdSize="8" class="request-directory__tabs request-directory__tabs-secondary">
                <NebulaTabs
                    :aria-label="$t('link-navigation-aria', { ns: 'requestdirectory' })"
                    class="request-directory__tabs-navigation"
                    :useFlatButton="!!isPartner"
                    :noBorderBottom="!!isPartner"
                >
                    <template v-slot:tabs>
                        <NebulaTabItem
                            v-for="(tab, index) in secondaryTabs"
                            :key="index"
                            :tab="tab"
                            :selected="tabActive[2] === index"
                            @click.native="handleTabClick(tab, index)"
                        />
                    </template>
                </NebulaTabs>
            </NebulaColumn>
        </NebulaGridRow>
        <NebulaGridRow type="flex" class="request-directory__filter-row">
            <NebulaColumn :columns="8" :mdSize="8" style="display: flex;">
                <!-- TODO: Remove comments when renabling tags -->
                <!-- <div style="display: flex; gap: 8px; width: 100%; flex-wrap: wrap;">
                    <NebulaTag v-for="(each, idx) in filters.subject"
                        :key="`tag-${idx}`"
                        :text="tagOptions.reduce((acc, tag) => (tag.value === each ? tag.text : acc), '')"
                        @click="clearTagFilter(each, 'subject')"
                    />
                    <NebulaTag v-for="(each, idx) in filters.grade_level"
                        :key="`grade_level-${idx}`"
                        :text="gradeLevelOptions.reduce((acc, grade_level) => (grade_level.value === each ? grade_level.text : acc), '')"
                        @click="clearTagFilter(each, 'grade_level')"
                    />
                    <NebulaTag v-for="(each, idx) in filters.type"
                        :key="`type-${idx}`"
                        :text="typeOptions.reduce((acc, type) => (type.value === each ? type.text : acc), '')"
                        @click="clearTagFilter(each, 'type')"
                    />
                    <NebulaTag v-for="(each, idx) in filters.time"
                        :key="`time-${idx}`"
                        :text="timeSlotOptions.reduce((acc, time) => (time.value === each ? time.label : acc), '')"
                        @click="clearTagFilter(each, 'time')"
                    />
                    <NebulaTag v-for="(each, idx) in filters.date"
                        :key="`date-${idx}`"
                        :text="formatDate(each, true)"
                        @click="clearTagFilter(each, 'date')"
                    />
                    <NebulaTag v-for="(each, idx) in filters.startDate"
                        :key="`startDate-${idx}`"
                        :text="`From: ${formatDate(each)}`"
                        @click="clearTagFilter(each, 'startDate')"
                    />
                    <NebulaTag v-for="(each, idx) in filters.endDate"
                        :key="`endDate-${idx}`"
                        :text="`To: ${formatDate(each)}`"
                        @click="clearTagFilter(each, 'endDate')"
                    />
                    <NebulaTag v-for="(each, idx) in filters.status"
                        :key="`status-${idx}`"
                        :text="requestStatusOptions.reduce((acc, status) => (status.value === each ? status.text : acc), '')"
                        @click="clearTagFilter(each, 'status')"
                    />
                    <NebulaTag v-for="(each, idx) in filters.employee_status"
                        :key="`employee_status-${idx}`"
                        :text="employeeRequestStatusOptions.reduce((acc, status) => (status.value === each ? status.text : acc), '')"
                        @click="clearTagFilter(each, 'employee_status')"
                    />
                </div> -->
            </NebulaColumn>
            <NebulaColumn :columns="4" :mdSize="8" class="request-directory__filters">
                <FilterPopover :numberOfFilters="numberOfFilters" @close="handleClosePopover" @clear-all-clicked="clearAllFilters">
                    <FilterList
                        :ref="filterOptions.date.ref"
                        v-if="filterOptions.date.visible"
                        topic="date"
                        title="Dates"
                        icon="calendar"
                    >
                        <template v-slot:selector>
                            <BrowseDatePicker
                                :hairline="false"
                                :selected="date"
                                @date-selected="handleDate"
                            />
                        </template>
                    </FilterList>
                    <FilterList
                        :ref="filterOptions.time.ref && filterOptions.time.visible"
                        v-if="flags.time_filters"
                        :items="timeSlotOptions"
                        :selected="filters.time"
                        topic="timeslot"
                        title="Time"
                        icon="clock"
                        @checkbox-toggled="handleTime"
                    />
                    <FilterList
                        :ref="filterOptions.subject.ref"
                        v-if="filterOptions.subject.visible"
                        :items="tagOptions"
                        :selected="filters.subject"
                        topic="subject"
                        title="Subject Area"
                        @checkbox-toggled="handleFilter"
                    />
                    <FilterList
                        v-if="filterOptions.status.visible"
                        :ref="filterOptions.status.ref"
                        :items="statusOptionsForRole"
                        :selected="filters.status"
                        topic="status"
                        title="Status"
                        @checkbox-toggled="handleFilter"
                    />
                    <FilterList
                        v-if="filterOptions.type.visible"
                        :ref="filterOptions.type.ref"
                        :items="typeOptions"
                        :selected="filters.type"
                        icon="assignment"
                        topic="type"
                        title="Type"
                        @checkbox-toggled="handleFilter"
                    />
                    <FilterList
                        v-if="filterOptions.grade.visible"
                        :ref="filterOptions.grade.ref"
                        :items="gradeLevelOptions"
                        :selected="filters.grade_level"
                        icon="learning"
                        topic="grade_level"
                        title="Grade"
                        @checkbox-toggled="handleFilter"
                    />
                </FilterPopover>
                <ViewModeButtons />
                <SortDropdown :options="sortOptions" :handleFunction="handleSort" />
            </NebulaColumn>
        </NebulaGridRow>
        <NebulaGridRow v-if="!loading && results && results.length > 0" type="flex">
            <NebulaColumn :columns="12">
                <RequestDirectoryTable
                    ref="table"
                    class="request-directory__list-view"
                    :data="results"
                    :mode="tableMode"
                />
            </NebulaColumn>
        </NebulaGridRow>
        <NebulaGridRow type="flex" v-else>
            <GrayBoxLoader card v-if="loading" />
            <NebulaColumn v-else :columns="12">
                <EmptySearch />
            </NebulaColumn>
        </NebulaGridRow>
        <PaginationRow
            v-if="paging"
            :paging="paging"
            :pagination="pagination"
            :loading="loading"
            :resultsLength="results.length"
            :updatePagination="updatePagination"
            @select-page="getResults"
        />
    </PageWrapper>
</template>

<script>
import {
    NebulaGrid,
    NebulaButton,
    NebulaTabs,
    NebulaTabItem,
    // NebulaVueSelect,
} from '@discoveryedu/nebula-components';
import PageHeader from '@/components/shared/PageHeader.vue';
import FilterPopover from '@/components/shared/tokens/FilterPopover.vue';
import FilterList from '@/components/shared/tokens/FilterList.vue';
import SortDropdown from '@/components/browse/SortDropdown.vue';
import RequestDirectoryTable from '@/components/shared/RequestDirectoryTable.vue';
import PaginationRow from '@/components/browse/PaginationRow.vue';
import EmptySearch from '@/components/browse/EmptySearch.vue';
import GrayBoxLoader from '@/components/shared/Loader/GrayBox.vue';
import ViewModeButtons from '@/components/shared/tokens/ViewModeButtons.vue';
import BrowseDatePicker from '@/components/shared/requests/BrowseDatePicker.vue';
import PageWrapper from '@/components/shared/layout/PageWrapper.vue';

import {
    roles,
    partnerRoles,
    actionRequired,
    completed,
    draft,
    expired,
    futureStatuses,
    requestDirectory as columns,
} from '@/constants';
import requestStatusMap from '@/data/requestStatusMap';
import viewModeButtons from '@/data/content/browse';

import createPayload from '@/mixins/data/createPayload';
import {
    inXdays,
    // timestampNow,
    thisWeek,
    thisMonth,
    thisYear,
    epochFromDateAndTime,
    getDatesInRange,
} from '@/data/placeholder/date';
import { browse, pagination } from '@/mixins/browse';
import { setUrlParam, removeUrlParam } from '@/utils/urlParams';

import {
    tagOptions,
    requestStatusOptions,
    timeSlotOptions,
    typeOptions,
    gradeLevelOptions,
    employeeRequestStatusOptions,
} from '@/data/formData';
import requestDirectory from '@/data/requestDirectory';
import scrollToTop from '@/mixins/scrollToTop';
import application from '@/mixins/requests/application';

const tabTypes = {
    time: ['week', 'month', 'year'],
    category: ['open-requests', 'my-connects', 'my-organization-connects'],
    status: [actionRequired, completed],
};

export default {
    name: 'RequestDirectory',
    components: {
        NebulaButton,
        NebulaGridRow: NebulaGrid.NebulaGridRow,
        NebulaColumn: NebulaGrid.NebulaColumn,
        NebulaTabs,
        NebulaTabItem,
        // NebulaVueSelect,
        PageHeader,
        FilterPopover,
        FilterList,
        SortDropdown,
        RequestDirectoryTable,
        PaginationRow,
        EmptySearch,
        GrayBoxLoader,
        ViewModeButtons,
        BrowseDatePicker,
        PageWrapper,
    },
    mixins: [createPayload, scrollToTop, pagination, browse, application],
    data() {
        return {
            employeeRequestStatusOptions,
            requestStatusOptions,
            typeOptions,
            gradeLevelOptions,
            viewModeButtons,
            tableMode: 'open',
            columns: [],
            date: null,
            filterOptions: {
                date: {
                    ref: 'dateFilter',
                    field: 'date',
                    visible: false,
                },
                time: {
                    ref: 'timeFilter',
                    field: 'time',
                    visible: false,
                },
                subject: {
                    ref: 'subjectFilter',
                    field: 'primarySubject',
                    visible: false,
                },
                status: {
                    ref: 'statusFilter',
                    field: 'status',
                    visible: false,
                },
                type: {
                    ref: 'typeFilter',
                    field: 'type',
                    visible: false,
                },
                grade: {
                    ref: 'gradeFilter',
                    field: 'gradeLevel',
                    visible: false,
                },
            },
            filters: {
                startDate: [],
                endDate: [],
                time: [],
                date: [],
                status: [],
                subject: [],
                type: [],
                grade_level: [],
                organizations: [],
                employee_status: [],
            },
            range: null,
            category: null,
            loading: true,
            sort: 'upcoming',
            grid: true,
            order: '',
            sortConfig: {
                upcoming: {
                    sort: 'startDate',
                    order: 'ASC',
                },
                'type-ascending': {
                    sort: 'type',
                    order: 'ASC',
                },
                'type-descending': {
                    sort: 'type',
                    order: 'DESC',
                },
                'subject-ascending': {
                    sort: 'subject',
                    order: 'ASC',
                },
                'subject-descending': {
                    sort: 'subject',
                    order: 'DESC',
                },
                'connector-ascending': {
                    sort: 'employee',
                    order: 'ASC',
                },
                'connector-descending': {
                    sort: 'subject',
                    order: 'DESC',
                },
            },
            tabActive: {
                1: null,
                2: null,
            },
            fetchAction: 'getRequests',
            tabMap: {
            },
            tabs: [],
            sortOptions: [
                {
                    value: 'upcoming',
                    label: 'Upcoming (default)',
                },
                {
                    value: 'type-ascending',
                    label: 'Type (A to Z)',
                },
                {
                    value: 'type-descending',
                    label: 'Type (Z to A)',
                },
                {
                    value: 'subject-ascending',
                    label: 'Subject Area (A to Z)',
                },
                {
                    value: 'subject-descending',
                    label: 'Subject Area (Z to A)',
                },
            ],
        };
    },
    computed: {
        tagOptions,
        timeSlotOptions,
        statusOptionsForRole() {
            if (this.isPartner) {
                const notSupported = [draft, expired];
                const options = requestStatusOptions.filter((status) => !notSupported.includes(status.value));

                const newOptions = [];
                options.forEach((status) => {
                    let { text } = status;
                    const { value } = status;

                    const handledStatus = Object.keys(requestStatusMap[this.appMode]).includes(status.value);
                    if (!handledStatus) {
                        newOptions.push({ text, value: [value] });
                        return;
                    }

                    const adjustedValue = requestStatusMap[this.appMode][status.value];
                    if (adjustedValue === 'accepting-applications') {
                        text = 'Accepting Applications';
                    } else {
                        const mappedDisplay = requestStatusOptions.find((each) => each.value === adjustedValue);
                        text = mappedDisplay.text;
                    }

                    const idx = newOptions.findIndex((each) => each.text === text);

                    if (idx >= 0) {
                        const copy = newOptions[idx];
                        copy.value.push(value);
                        newOptions[idx] = copy;
                    } else {
                        newOptions.push({ text, value: [value] });
                    }
                });

                return newOptions;
            }
            return requestStatusOptions;
        },
        flags() {
            return this.$store.getters.flags;
        },
        allTabs() {
            return requestDirectory[this.appMode];
        },
        primaryTabs() {
            return this.allTabs.filter((each) => each.level === 1);
        },
        secondaryTabs() {
            return this.allTabs.filter((each) => {
                if (each.level !== 2) {
                    return false;
                }

                if (each.mode) {
                    return this.tableMode === each.mode;
                }

                return true;
            });
        },
        appMode() {
            return this.$store.state.app.mode;
        },
        isEmployee() {
            return this.appMode === roles.EMPLOYEE_ROLE;
        },
        showStatus() {
            if (this.appMode === roles.EDUCATOR_ROLE) {
                return true;
            }

            return this.tableMode === 'self';
        },
        isEducator() {
            return this.appMode === roles.EDUCATOR_ROLE;
        },
        isPartner() {
            return partnerRoles.includes(this.appMode);
        },
        breadcrumbs() {
            const fallbackBreadcrumb = {
                dim_text: '',
                link: '/',
                text: 'Dashboard',
            };

            const storedBreadcrumbs = this.$store.state.app.breadcrumb;
            return storedBreadcrumbs.length > 1
                ? storedBreadcrumbs : [fallbackBreadcrumb].concat(storedBreadcrumbs);
        },
        results() {
            return this.$store.state.page.requests;
        },
        numberOfFilters() {
            return Object.keys(this.filters).reduce((acc, key) => acc + (Array.isArray(this.filters[key]) ? this.filters[key].length : 0), 0);
        },
    },
    methods: {
        updateBreadcrumb() {
            const { pathname, search } = window.location;
            this.$store.dispatch('updateBreadcrumb', {
                link: `${pathname}${search}`,
                text: 'Connect Directory',
            });
        },
        clearTagFilter(item, filter) {
            const idx = this.filters[filter].findIndex((each) => each === item);
            if (idx >= 0) {
                this.filters[filter].splice(idx, 1);
            }
            if (filter === 'date') {
                this.applyDefaultFilters(['startDate', 'endDate']);
            } else if (filter === 'time' && this.filters.date.length > 0) {
                this.updateDateRangesFromTimeSlots();
            }
            this.getResults();
        },
        async handleTabClick(tab, idx) {
            const { level, value } = tab;

            if (level === 1 && this.tabActive[1] === idx) {
                // primary tab already selected, do nothing
                return;
            }

            // returns null if a deselect
            const result = await this.applyTab(tab, idx);

            if (!result) {
                return;
            }

            setUrlParam(level, value);
            this.updateBreadcrumb();
            this.setup();
            this.getResults();
        },
        updateFilterVisibility() {
            const filters = Object.keys(this.filterOptions);
            filters.forEach((key) => {
                const filterField = this.filterOptions[key].field;
                const match = this.columns.find((column) => column.field === filterField);
                if (!match) {
                    this.filterOptions[key].visible = false;
                    return;
                }

                this.filterOptions[key].visible = true;
            });
        },
        setTableMode() {
            const selfTabs = ['my-organization-connects', 'my-connects'];

            if (this.appMode === roles.EDUCATOR_ROLE) {
                this.tableMode = 'directory';
                return;
            }
            const activePrimary = this.primaryTabs[this.tabActive['1']];
            if (activePrimary && selfTabs.includes(activePrimary.value)) {
                this.tableMode = 'self';
                return;
            }

            this.tableMode = 'open';
        },
        setColumns() {
            this.columns = columns[this.appMode.toLowerCase()][this.tableMode];
        },
        async applyTab(tab, idx) {
            this.loading = true;
            const { value, level } = tab;
            // map tab "type" to the specific tab name passed in

            if (!tab) {
                return null;
            }

            const tabType = Object.keys(tabTypes).find((type) => tabTypes[type].includes(value));

            // if clicking on a selected tab, remove the setting and reset the appropriate filters
            // not applicable to level 1, which does nothing if already selected, and is prevented a step earlier
            if (this.tabActive[level] === idx && level !== 1) {
                this.tabActive[level] = null;

                removeUrlParam(level, value);

                this.fetchAction = 'getRequests';
                if (tab.filters) {
                    this.applyDefaultFilters(tab.filters && Object.keys(tab.filters));
                } else if (tabType === 'time') {
                    this.applyDefaultFilters(['startDate', 'endDate']);
                }

                this.getResults();
                return null;
            }

            // tabs that control startTime and endTime parameters
            if (tabType === 'time') {
                if (this.category) {
                    const currentCategoryTab = this.allTabs.find((each) => each.value === this.category);
                    if (currentCategoryTab.filters) {
                        const { filters } = currentCategoryTab;
                        const filterKeys = Object.keys(filters);
                        filterKeys.forEach((key) => {
                            this.filters[key] = filters[key];
                        });
                    }
                }
                this.range = 'week';

                if (value === 'month') {
                    this.range = 'month';
                }

                if (value === 'year') {
                    this.range = 'year';
                }
            }

            // tabs that apply or remove request status parameters
            // if (tabType === 'status') {
            //     const handledRole = Object.keys(requestStatusMap).includes(this.appMode);
            //     if (handledRole) {
            //         const roleMap = requestStatusMap[this.appMode];
            //         const interpretedStatuses = Object.keys(roleMap);
            //         const match = interpretedStatuses.find((status) => roleMap[status] === value);
            //         this.filters.status = [match];
            //     } else {
            //         this.filters.status = [value];
            //     }
            // }

            this.tabActive[level] = idx;

            this.setTabFilters(tab);

            if (tabType === 'category') {
                this.category = value;
                this.setTableMode();
                this.checkSecondaryTab();
            }

            return { level, value };
        },
        setTabFilters(tab) {
            // apply any filters built into the taxb setting
            if (!tab.filters) {
                return;
            }

            const filters = Object.keys(tab.filters);
            filters.forEach((filter) => {
                this.filters[filter] = tab.filters[filter];
            });
        },
        async getResults() {
            this.loading = true;
            this.scrollToTop();
            const params = this.getCombinedParams();

            const payload = await this.createPayload({ params });
            await this.$store.dispatch(this.fetchAction, payload);

            this.updateBreadcrumb();
            this.loading = false;
        },
        resetFilters(filters) {
            filters.forEach((filter) => {
                this.filters[filter] = [];
            });
        },
        handleClosePopover() {
            Object.keys(this.filterOptions).forEach((key) => {
                const ref = this.$refs[this.filterOptions[key].ref];
                if (ref) {
                    ref.collapse();
                }
            });
        },
        resetFilter(filter) {
            this.filters[filter] = [];
        },
        // default to entire list, but can be specified
        applyDefaultFilters(filters = ['startDate', 'endDate', 'status', 'subject', 'type']) {
            this.date = null;
            filters.forEach((filter) => {
                this.resetFilter(filter);
            });

            if (filters.includes('startDate')) {
                this.updateFilter({
                    topic: 'startDate',
                    value: inXdays(-30),
                });

                this.updateFilter({
                    topic: 'endDate',
                    value: inXdays(365),
                });
            }

            if (filters.includes('status')) {
                futureStatuses.forEach((statusOption) => {
                    this.updateFilter({
                        topic: 'status',
                        value: statusOption,
                    });
                });
            }
        },
        getTimestampsInRange() {
            const { time } = this.filters;
            let { date = [] } = this.filters;
            const timeRanges = [];
            // if (time && time.length > 0) {
            //     console.log('timeslot stored');
            // }

            // if there's no date selected but a range has been selected
            if (date.length < 1 && this.range) {
                date = getDatesInRange(this.range);
            }

            time.forEach((timeslot) => {
                date.forEach((each) => {
                    const { startDate, endDate } = epochFromDateAndTime(each, timeslot);

                    timeRanges.push({ startDate, endDate });
                });
            });

            return timeRanges;
        },
        applyRange() {
            const { range } = this;
            let params;

            if (range === 'week') {
                params = thisWeek();
            }

            if (range === 'month') {
                params = thisMonth();
            }

            if (range === 'year') {
                params = thisYear();
            }

            const { startDate, endDate } = params;

            this.filters.startDate = [startDate];
            this.filters.endDate = [endDate];
        },
        mergeTimeRanges(timeStampRanges) {
            // Sort ranges by start time
            timeStampRanges.sort((a, b) => a.startDate - b.startDate);

            // Merge adjacent time slots
            const mergedRanges = timeStampRanges.reduce((acc, current) => {
                if (acc.length === 0) {
                    acc.push(current);
                    return acc;
                }

                const lastRange = acc[acc.length - 1];
                // If current start time is the same as last end time, merge them
                if (current.startDate <= lastRange.endDate) {
                    lastRange.endDate = Math.max(lastRange.endDate, current.endDate);
                } else {
                    acc.push(current);
                }
                return acc;
            }, []);

            this.filters.startDate = mergedRanges.map((range) => range.startDate);
            this.filters.endDate = mergedRanges.map((range) => range.endDate);
        },
        updateDateRangesFromTimeSlots() {
            if (this.filters.time.length > 0) {
                const timeStampRanges = this.getTimestampsInRange();
                if (timeStampRanges.length > 0) {
                    this.mergeTimeRanges(timeStampRanges);
                }
            } else if (this.filters.date.length > 0) {
                // If no time filters left but we have a date, set to full day range
                const { startDate, endDate } = epochFromDateAndTime(this.filters.date[0]);
                this.filters.startDate = [startDate];
                this.filters.endDate = [endDate];
            }
        },
        handleTime(timeslot) {
            this.updateFilter({
                topic: 'time',
                value: timeslot.value,
            });

            // Only reset and recalculate if we have no time filters left
            if (this.filters.time.length < 1) {
                if (this.range) {
                    this.applyRange();
                }
                return;
            }

            // Only modify start/end dates if we have a specific date selected
            if (this.filters.date.length > 0) {
                this.updateDateRangesFromTimeSlots();
            }

            this.getResults();
        },
        handleDate(date) {
            // tab handling if one is selected. Deselect any date-based tabs
            const activeIdx = this.tabActive[2];

            if (activeIdx || activeIdx === 0) {
                const currentTabObj = this.secondaryTabs[activeIdx];
                if (tabTypes.time.includes(currentTabObj.value)) {
                    this.applyTab(currentTabObj, activeIdx);
                }
            }
            this.date = date;

            this.filters.date = [date];

            this.updateDateRangesFromTimeSlots();

            this.getResults();
        },
        changeViewMode() {
            this.data.grid = !this.data.grid;
        },
        applyDefaultTab() {
            const primary = this.primaryTabs[0];

            if (this.isPartner) {
                this.applyTab(primary, 0);
            }
        },
        checkSecondaryTab() {
            // handles secondary tab settings or removal when the primary tab is changed
            const params = new URLSearchParams(window.location.search);
            const activeValue = params.get('2');
            const activeIdx = this.tabActive[2];
            const activeTab = this.allTabs.find((each) => each.value === activeValue);

            if (activeIdx === null || !activeValue) {
                return;
            }

            const values = this.secondaryTabs.map((each) => each.value);
            if (!values.includes(activeValue.trim().toLowerCase())) {
                // remove active tab through usual mechanism if not supported under the new selected primary tab
                this.applyTab(activeTab, activeIdx);
            } else {
                this.setTabFilters(activeTab);
            }
        },
        async applyURLparams() {
            const paramsOnLoad = this.$route.query;
            const { 1: primary, 2: secondary } = paramsOnLoad;
            if (!primary && !secondary) {
                this.applyDefaultTab();
                return;
            }

            if (primary) {
                const primaryIdx = this.primaryTabs.findIndex((each) => each.value === primary);
                if (primaryIdx < 0) {
                    return;
                }
                await this.applyTab(this.primaryTabs[primaryIdx], primaryIdx);
            }

            if (secondary) {
                const secondaryIdx = this.secondaryTabs.findIndex((each) => each.value === secondary);
                if (secondaryIdx < 0) {
                    return;
                }
                this.applyTab(this.secondaryTabs[secondaryIdx], secondaryIdx);
            }
        },
        setup() {
            this.setTableMode();
            this.setColumns();
            this.updateFilterVisibility();
        },
        clearAllFilters() {
            let excluded = [];
            if (this.range) {
                excluded = ['startDate', 'endDate'];
            } else {
                this.tabActive[2] = null;
            }
            this.applyDefaultFilters();
            const filters = Object.keys(this.filters).filter((key) => !excluded.includes(key));
            filters.forEach((filter) => {
                this.filters[filter] = [];
            });
            this.getResults();
        },
        formatDate(dateString, excludeTime = false) {
            // Handle Unix timestamp (in seconds or milliseconds)
            const timestamp = parseInt(dateString, 10);
            const date = Number.isInteger(timestamp)
                ? new Date(timestamp < 1e12 ? timestamp * 1000 : timestamp)
                : new Date(dateString);

            return date.toLocaleDateString('en-US', {
                month: '2-digit',
                day: '2-digit',
                year: 'numeric',
                ...(excludeTime ? {} : {
                    hour: '2-digit',
                    minute: '2-digit',
                }),
            });
        },
    },
    async mounted() {
        this.scrollToTop();
        this.$store.dispatch('resetPageData');

        if (this.appMode === roles.EMPLOYEE_ROLE) {
            this.getApplications();
        }

        this.loading = true;

        this.applyDefaultFilters();

        this.applyPageSettings();
        await this.applyURLparams();
        this.setup();

        this.getResults();
        this.initializePagination();
    },
    beforeDestroy() {
        this.$store.dispatch('resetPageData');
    },
};
</script>

<style lang="stylus">
.request-directory {
    &__tabs, &__filters {
        display: flex;
    }

    &__tabs {
        @media (min-width: $nebula-breakpoints-tablet-portrait) {
            justify-content: flex-start;
        }
    }

    &__tabs-primary {
        border-bottom: 1px solid $nebula-color-platform-interactive-300;
    }

    &__tabs-secondary {
        --nebula-tab-item-color: $nebula-color-platform-interface-900;
    }

    &__filters {
        @media (min-width: $nebula-breakpoints-tablet-portrait) {
            justify-content: flex-end;
        }
    }

    &__create-button {
        @media (min-width: $nebula-breakpoints-tablet-portrait) {
            width: 100%;
            justify-content: center;
        }
    }

    &__filter-row {
        margin-block-end: $nebula-space-4x;
    }

    &__view-buttons {
        align-self: center;
    }

    .nebula-tabs__list .nebula-tabs__list-item--selected .nebula-tabs__tab-link {
        --nebula-button-background: $nebula-color-platform-interface-400;
        &:hover {
            --nebula-button-background-hover: $nebula-color-platform-interface-400;
        }
    }
    .nebula-tabs__list .nebula-tabs__list-item .nebula-tabs__tab-link {
        --nebula-button-background-hover: $nebula-color-platform-interface-400;
        &:hover {
            --nebula-button-background-hover: $nebula-color-platform-interface-400;
        }
    }
    .nebula-tabs__list--no-border {
        --nebula-tab-item-color-selected: $nebula-color-platform-interface-900;
    }
}
</style>
